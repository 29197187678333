@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-background-dark font-lexend font-normal;
  }

  :root {
    @apply h-full bg-background-dark;
  }

  @screen sm {
    .plans-container {
      max-width: 640px;
    }
  }

  @screen md {
    .plans-container {
      max-width: 768px;
    }
  }

  @screen lg {
    .plans-container {
      max-width: 1024px;
    }
  }

  @screen xl {
    .plans-container {
      max-width: 1024px;
    }
  }

  @screen 2xl {
    .plans-container {
      max-width: 1024px;
    }
  }

  @screen sm {
    .subscriptions-container {
      max-width: 640px;
    }
  }

  @screen md {
    .subscriptions-container {
      max-width: 728px;
    }
  }

  @screen lg {
    .subscriptions-container {
      max-width: 728px;
    }
  }

  @screen xl {
    .subscriptions-container {
      max-width: 728px;
    }
  }

  @screen 2xl {
    .subscriptions-container {
      max-width: 728px;
    }
  }
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }

  .markdown a {
    @apply text-bult-400 underline;
  }

  .chat-markdown p {
    @apply mt-3;
  }

  .chat-markdown strong {
    @apply text-secondary-bold;
  }

  .chat-markdown ol {
    @apply list-disc px-4;
  }

  .markdown strong {
    @apply text-body-bold;
  }

  .terms p {
    @apply mt-4;
  }

  .terms h1 {
    @apply mt-4 text-2xl-semibold;
  }

  .terms ol {
    @apply mt-4;
  }

  .terms h2 {
    @apply mt-4;
  }
}

@layer components {
  .header1 {
    @apply font-lexend text-5xl font-bold;
  }

  .header3 {
    @apply font-lexend text-4xl font-medium;
  }

  .header4 {
    @apply font-lexend text-3xl font-medium;
  }

  .header5 {
    @apply font-lexend text-2xl font-medium;
  }

  .header6 {
    @apply font-lexend text-lg font-medium;
  }

  .text-4xl-semibold {
    @apply font-lexend text-4xl font-semibold;
  }

  .text-5xl-bold {
    @apply font-lexend text-5xl font-bold;
  }

  .text-4xl-bold {
    @apply font-lexend text-4xl font-bold;
  }

  .text-2xl-semibold {
    @apply font-lexend text-2xl font-semibold;
  }

  .text-2xl-medium {
    @apply font-lexend text-2xl font-medium;
  }

  .text-2xl-bold {
    @apply font-lexend text-2xl font-bold;
  }

  .text-2xl-light {
    @apply font-lexend text-2xl font-light;
  }

  .text-2xl-normal {
    @apply font-lexend text-2xl font-normal;
  }

  .text-xl-normal {
    @apply font-lexend text-xl font-normal;
  }

  .text-xl-bold {
    @apply font-lexend text-xl font-bold;
  }

  .text-xl-medium {
    @apply font-lexend text-xl font-medium;
  }

  .text-lg-semibold {
    @apply font-lexend text-lg font-semibold;
  }

  .text-lg-medium {
    @apply font-lexend text-lg font-medium;
  }

  .text-lg-light {
    @apply font-lexend text-lg font-light;
  }

  .text-lg-bold {
    @apply font-lexend text-lg font-bold;
  }

  .text-lg-semibold {
    @apply font-lexend text-lg font-semibold;
  }

  .text-lg-normal {
    @apply font-lexend text-lg font-normal;
  }

  .text-body-normal {
    @apply font-lexend text-base font-normal;
  }

  .text-body-semibold {
    @apply font-lexend text-base font-semibold;
  }

  .text-body-bold {
    @apply font-lexend text-base font-bold;
  }

  .text-body-medium {
    @apply font-lexend text-base font-medium;
  }

  .text-body-light {
    @apply font-lexend text-base font-light;
  }

  .text-secondary-normal {
    @apply font-lexend text-sm font-normal;
  }

  .text-xl-normal {
    @apply font-lexend text-xl font-normal;
  }

  .text-xl-light {
    @apply font-lexend text-xl font-light;
  }

  .text-xs-bold {
    @apply font-lexend text-xs font-bold;
  }

  .text-xs-semibold {
    @apply font-lexend text-xs font-semibold;
  }

  .text-xs-medium {
    @apply font-lexend text-xs font-semibold;
  }

  .text-xs-normal {
    @apply font-lexend text-xs font-normal;
  }

  .text-secondary-semibold {
    @apply font-lexend text-sm font-semibold;
  }

  .text-secondary-medium {
    @apply font-lexend text-sm font-medium;
  }

  .text-secondary-light {
    @apply font-lexend text-sm font-light;
  }

  .text-secondary-bold {
    @apply font-lexend text-sm font-bold;
  }

  .text-caption-semibold {
    @apply font-lexend text-xs font-semibold;
  }

  .text-caption-bold-tracking-wide {
    @apply font-lexend text-xs font-bold tracking-wide;
  }

  .text-caption-bold {
    @apply font-lexend text-xs font-bold;
  }

  .text-caption-medium {
    @apply font-lexend text-xs font-medium;
  }

  .text-caption-normal {
    @apply font-lexend text-xs font-normal;
  }

  .text-2xs-medium {
    @apply font-lexend text-2xs font-medium;
  }

  .text-2xs-normal {
    @apply font-lexend text-2xs font-normal;
  }

  .text-caption-light {
    @apply font-lexend text-xs font-light;
  }

  .text-btn {
    @apply font-lexend text-sm font-bold;
  }

  .text-error {
    @apply font-lexend text-xs font-light text-red-500;
  }

  .input-primary {
    @apply appearance-none rounded-xl border border-gray2 px-3 py-4 font-lexend leading-tight text-gray-700 placeholder:font-lexend placeholder:text-base placeholder:text-gray3 focus-within:border-gray5 focus-within:text-gray5 focus-within:ring-gray5 focus:outline-none focus:ring-0;
  }

  .input-primary-dark {
    @apply text-color-gray5 focus-within:text-color-gray5 appearance-none rounded-lg border border-gray2 px-3 py-3 font-lexend leading-tight focus-within:border-gray5 focus-within:ring-gray5 focus:outline-none focus:ring-0 dark:border-0;
  }

  .input-basic-transparent {
    @apply text-secondary-light text-color-gray5 bg-transparent block w-full rounded-md border border-fizz-600 px-3 py-2.5 shadow-sm placeholder:text-fizz-200 focus:border-indigo-500 focus:outline-none focus:ring-transparent sm:text-sm;
  }

  .input-basic-dark {
    @apply text-secondary-light text-color-gray5 bg-input block w-full rounded-md border border-darul-500 px-3 py-2.5 shadow-sm placeholder:text-darul-200 focus:border-indigo-500 focus:outline-none focus:ring-transparent sm:text-sm;
  }

  .input-basic-dark-v2 {
    @apply text-body-normal bg-fizz-600 autofill:bg-fizz-800 block w-full border border-transparent px-2.5 py-3.5 text-fizz-0 shadow-sm placeholder:text-fizz-200 focus:ring-transparent focus:border-bung-400 focus:outline-none sm:text-sm;
  }

  .input-basic-dark-v3 {
    @apply text-body-normal bg-fizz-900 autofill:bg-fizz-900 block w-full rounded-md border border-fizz-500 px-5 py-3.5 text-fizz-0 shadow-sm placeholder:text-fizz-400 focus:ring-transparent focus:border-bung-400 focus:outline-none sm:text-sm;
  }

  .input-basic-dark-v4 {
    @apply text-body-normal bg-darul-700 autofill:bg-fizz-800 block w-full border border-transparent px-2.5 py-3.5 text-fizz-0 shadow-sm placeholder:text-fizz-200 focus:ring-transparent focus:border-bung-400 focus:outline-none sm:text-sm;
  }

  .select-basic {
    @apply text-body-normal bg-fizz-600 text-fizz-0 block w-full border border-transparent px-2.5 py-3.5 focus:text-fizz-0 shadow-sm  focus:ring-transparent focus:border-bung-400 focus:outline-none sm:text-sm invalid:bg-red-500;
  }

  .multi-select-basic {
    @apply text-body-normal bg-fizz-600 text-fizz-0 block w-full border border-transparent;
  }

  .react-select-container {
    @apply text-secondary-light text-color-gray5 bg-fizz-800 block w-full rounded-md border border-darul-500 px-3 py-2.5 shadow-sm placeholder:text-darul-400 focus:border-indigo-500 focus:outline-none focus:ring-transparent sm:text-sm;
  }

  .react-select-container-v2 {
    @apply text-secondary-light block w-full border-none bg-fizz-800 px-3 py-2.5 text-[#FFFFFF] shadow-sm placeholder:text-darul-400 focus:border-indigo-500 focus:outline-none focus:ring-transparent sm:text-sm;
  }

  .react-select-container-v3 {
    @apply rounded-lg text-secondary-light block w-full border-none bg-fizz-800 px-3.5 py-3 text-[#FFFFFF] shadow-sm placeholder:text-darul-400 focus:border-indigo-500 focus:outline-none focus:ring-transparent sm:text-sm;
  }

  .input-in-game-name {
    @apply text-secondary-light text-color-gray5 bg-input autofill:bg-input block w-full rounded-lg border border-darul-600 px-3 py-3.5 shadow-sm placeholder:text-darul-300 focus:border-transparent focus:outline-none focus:ring-lobBlue sm:text-sm;
  }

  .input-in-game-name-v2 {
    @apply text-secondary-light bg-input-v2 autofill:bg-input-v2 block w-full border border-transparent px-3 py-3.5 text-fizz-1000 shadow-sm placeholder:text-fizz-1000 focus:border-transparent focus:outline-none focus:ring-lobBlue sm:text-sm font-sans;
  }

  .input-basic {
    @apply bg-input text-color-gray5 focus-within:text-color-gray5 text-secondary-normal w-full appearance-none rounded-lg border border-darul-600 px-4 py-5 font-lexend font-light leading-tight placeholder:text-transparent focus-within:border-darul-600 focus-within:ring-darul-600 focus:pb-2
    focus:pt-8 focus:outline-none
    focus:ring-0 not-placeholder-shown:pb-3
    not-placeholder-shown:pt-7;
  }

  .input-basic-v2 {
    @apply bg-input-v2 text-secondary-normal w-full appearance-none border border-transparent px-4 py-5 font-lexend font-light leading-tight text-black placeholder:text-transparent focus-within:border-transparent focus-within:text-black focus-within:ring-darul-600 focus:pb-2
    focus:pt-8 focus:outline-none
    focus:ring-0 not-placeholder-shown:pb-3
    not-placeholder-shown:pt-7;
  }

  .input-basic-select {
    @apply bg-input-v2 text-secondary-light  w-full  appearance-none border border-transparent  px-3 py-3.5 font-lexend font-light  leading-tight text-fizz-1000 placeholder:text-transparent focus-within:border-gray5 focus-within:text-fizz-1000 focus-within:ring-gray5
    focus:border-transparent focus:outline-none focus:ring-lobBlue;
  }

  .input-basic-label {
    @apply text-secondary-normal peer-focus:text-caption-normal peer-not-placeholder-shown:text-caption-normal pointer-events-none absolute left-0 top-0 origin-top-left px-4
    py-5
    text-black
    transition-transform
    duration-100
    peer-focus:-translate-y-3
    peer-not-placeholder-shown:-translate-y-3;
  }

  .text-color-primary {
    @apply text-black dark:text-white;
  }

  .text-color-gray5 {
    @apply text-gray5 dark:text-gray5-dark;
  }

  .text-color-gray4 {
    @apply text-gray4 dark:text-gray4-dark;
  }

  .text-color-gray3 {
    @apply text-gray3 dark:text-gray3-dark;
  }

  .tooltip {
    --tooltip-color: #f0f0f0;
    --tooltip-text-color: #000000;
  }

  .tooltip {
    @apply text-body-medium;
  }

  .bg1 {
    @apply bg-gray1 dark:bg-gray1-dark;
  }

  .bg-modal {
    @apply bg-white dark:bg-darul-800;
  }

  .bg2 {
    @apply bg-gray2 dark:bg-gray2-dark;
  }

  .bgw {
    @apply bg-white dark:bg-white-dark;
  }

  .bg-input {
    @apply bg-gray1 dark:bg-darul-700;
  }

  .bg-input-v2 {
    @apply bg-gray-0 dark:bg-gray-0;
  }

  .bg-nav {
    @apply bg-white dark:bg-background-dark;
  }

  .bg {
    @apply bg-gray-50 dark:bg-background-dark;
  }

  .btn-primary-dark {
    @apply text-secondary-bold btn w-full rounded-lg border border-darul-500 bg-darul-600 text-white hover:border-darul-500 hover:opacity-80 dark:bg-darul-600 dark:text-white;
  }

  .btn-primary-dark-sm {
    @apply text-secondary-normal btn-sm btn h-10 w-full rounded-lg border border-darul-500 bg-darul-600 text-white hover:border-darul-500 hover:opacity-80 dark:bg-darul-600 dark:text-white;
  }

  .btn-primary-dark-sm-v2 {
    @apply text-body-bold btn-sm btn h-10 w-full rounded-none border border-fizz-500 bg-fizz-800 text-white hover:border-fizz-500 hover:bg-fizz-600;
  }

  .btn-primary {
    @apply text-secondary-bold btn rounded-lg border border-gray2 bg-gray5 text-white hover:border-gray2 hover:opacity-80 dark:bg-gray5-dark dark:text-white-dark;
  }

  .btn-lobblue {
    @apply text-secondary-bold btn rounded-lg border border-lobBlue bg-lobBlue text-white hover:border-lobBlue hover:opacity-80 disabled:opacity-50 dark:bg-lobBlue dark:text-white;
  }

  .btn-v2-white {
    @apply btn h-15 rounded-none border border-fizz-1000 bg-white font-fjalla text-base uppercase text-black hover:border-fizz-1000 hover:opacity-80 disabled:opacity-50 dark:bg-white dark:text-black;
  }

  .btn-transparent {
    @apply btn h-15 rounded-none border border-fizz-500 bg-transparent font-fjalla text-base uppercase text-fizz-100 hover:border-fizz-500 hover:opacity-80 disabled:opacity-50 dark:bg-transparent dark:text-fizz-100;
  }

  .btn-v2-primary {
    @apply btn h-15 rounded-none border border-bung-400 bg-bung-400 font-fjalla text-base uppercase text-black hover:border-bung-400 hover:bg-bung-400 focus:hover:bg-bung-400 disabled:opacity-50 dark:bg-bung-400 dark:text-black;
  }

  .btn-v2-bg-fizz-800 {
    @apply btn h-15 rounded-none border border-fizz-800 bg-fizz-800 font-fjalla text-base uppercase text-fizz-200 hover:border-fizz-800 hover:bg-fizz-800 focus:hover:bg-fizz-800 disabled:opacity-50 dark:bg-fizz-800 dark:text-fizz-200;
  }

  .btn-v2-new {
    @apply btn h-15 rounded-none bg-fizz-1000 border border-white hover:border-white font-fjalla text-base uppercase text-white hover:bg-fizz-1000 focus:hover:bg-fizz-1000 disabled:opacity-50 dark:bg-fizz-1000 dark:text-white;
  }

  .btn-v2-lobblue {
    @apply btn h-15 rounded-none border border-lobBlue bg-lobBlue font-fjalla text-base uppercase text-black hover:border-lobBlue hover:bg-lobBlue focus:hover:bg-lobBlue disabled:opacity-50;
  }

  .btn-v2-warning {
    @apply btn h-15 rounded-none border border-swain-400 bg-swain-400 font-fjalla text-base uppercase text-black hover:border-swain-400 hover:bg-swain-400 focus:hover:bg-swain-400 disabled:opacity-50 dark:bg-swain-400 dark:text-black;
  }

  .btn-v2-fizz-600 {
    @apply btn h-15 rounded-none border border-fizz-500 bg-fizz-600 font-fjalla text-base uppercase text-black hover:border-fizz-500 hover:bg-fizz-600 focus:hover:bg-fizz-600 disabled:opacity-50 dark:bg-fizz-600 dark:text-white;
  }

  .btn-v2-fizz-500 {
    @apply btn h-15 rounded-none border border-fizz-500 bg-fizz-500 font-fjalla text-base uppercase text-black hover:border-fizz-500 hover:bg-fizz-400  disabled:opacity-50 dark:bg-fizz-500 dark:text-white;
  }

  .btn-v2-fizz-400 {
    @apply btn h-15 rounded-none border border-fizz-300 bg-fizz-400 font-fjalla text-base uppercase text-black hover:border-fizz-300 hover:bg-fizz-400 focus:hover:bg-fizz-400 disabled:opacity-50 dark:bg-fizz-400 dark:text-white;
  }

  .btn-white-thresh {
    @apply text-secondary-bold btn rounded-lg border border-white bg-white text-thresh-600 hover:border-thresh-600 hover:opacity-80 disabled:opacity-50 dark:bg-white dark:text-thresh-600;
  }

  .btn-white-swain {
    @apply text-body-bold btn rounded-lg border border-white bg-white text-swain-600 hover:border-swain-600 hover:opacity-80 disabled:opacity-50 dark:bg-white;
  }

  .btn-white-swain {
    @apply text-secondary-bold btn rounded-lg border border-white bg-white text-swain-600 hover:border-swain-600 hover:opacity-80 disabled:opacity-50 dark:bg-white dark:text-swain-600;
  }

  .btn-bult-300 {
    @apply text-secondary-bold btn rounded-lg border border-lobBlue bg-bult-300 text-white hover:border-lobBlue hover:opacity-80 dark:bg-lobBlue dark:text-white;
  }

  .input-radio-basic {
    @apply absolute left-0 top-0 flex h-5 w-5 -translate-x-1/2 -translate-y-1/2 cursor-pointer rounded-full border border border-2 border-fizz-500 peer-checked:border-6 peer-checked:border-fizz-1000;
  }

  .btn-rose-600 {
    @apply text-secondary-medium btn h-12 rounded-lg bg-rose-600 normal-case text-white hover:scale-105 hover:bg-rose-600 hover:opacity-80;
  }

  .modal-mask {
    @apply fixed inset-0 cursor-pointer bg-black bg-opacity-85;
  }

  .modal-dialog {
    @apply relative w-full transform overflow-visible bg-white pb-9 pt-6 text-left shadow-xl transition-all md:w-128;
  }

  .modal-title {
    @apply mt-4 font-fjalla text-2xl font-normal uppercase text-fizz-1000;
  }

  .modal-content {
    @apply flex w-full flex-col items-center gap-4 px-4 sm:px-8;
  }

  .booking-card {
    @apply z-20 flex flex-col items-center justify-center bg-fizz-900 px-4 pb-6 pt-6 sm:px-8;
  }

  .booking-card-title {
    @apply text-2xl-semibold text-color-gray5 mx-8 text-center font-oswald;
  }

  .border2 {
    @apply border border-gray2 dark:border-gray2-dark;
  }

  .btn-status {
    @apply text-btn btn absolute sticky mx-12 rounded-full border-none px-8 normal-case text-white shadow-lg hover:opacity-80;
  }

  .btn {
    @apply text-secondary-medium h-13 normal-case shadow-none;
  }

  #sendbird-message-input-text-field {
    @apply text-body-normal border border-transparent  bg-fizz-600 text-white placeholder-fizz-300  focus:border-fizz-300 !important;
  }

  [type='radio'] {
    @apply bg-transparent;
  }

  .article img {
    @apply mt-4;
  }

  .article h1 {
    @apply text-4xl-bold mb-4 mt-8 text-white;
  }

  .article h2 {
    @apply text-2xl-bold mt-8 text-white;
  }

  .article h3 {
    @apply text-xl-bold mt-8 text-white;
  }

  .article p {
    @apply mt-4;
  }

  .article li {
    @apply mt-4;
  }

  .term h1 {
    @apply text-4xl-bold mb-4 mt-8 text-white;
  }

  .term h2 {
    @apply text-2xl-bold mt-8 text-white;
  }

  .term p {
    @apply mt-8;
  }

  .menu li > *:not(ul):not(.menu-title):not(details):active,
  .menu li > *:not(ul):not(.menu-title):not(details).active,
  .menu li > details > summary:active {
    @apply bg-bung-300 text-black [@media(hover:hover)]:bg-bung-300 [@media(hover:hover)]:text-black;
  }

  .dropdown-link {
    @apply hover:bg-bung-300 text-body-medium text-fizz-1000 focus:text-black active:text-black cursor-pointer;
  }

  .social-mode-icon {
    @apply h-[45px] lg:h-[60px] w-[52.2px] lg:w-[69.6px] flex-shrink-0 absolute left-1/2 transform -translate-x-1/2 transition duration-500;
  }

  .social-mode-bottom-icon {
    @apply h-[50.4px] lg:h-[67.2px] w-[65.25px] lg:w-[87px] flex-shrink-0 absolute left-1/2 transform -translate-x-1/2 transition duration-500 lg:top-[78px] top-[58.5px];
  }

  .social-mode-bottom-icon-selected {
    @apply social-mode-bottom-icon translate-y-1.5 md:translate-y-2.5;
  }

  .social-mode-left-icon {
    @apply social-mode-icon top-[0px] opacity-0;
  }

  .social-mode-left-icon-selected {
    @apply social-mode-left-icon opacity-100 -translate-x-[4.2rem] -translate-y-0 lg:-translate-x-[5.4rem];
  }

  .social-mode-right-icon {
    @apply social-mode-icon lg:top-[25px] top-[20px];
  }

  .social-mode-right-icon-selected {
    @apply social-mode-right-icon translate-x-3 md:translate-x-4 translate-y-5;
  }

  .streamer-mode-icon {
    @apply h-[60px] w-[52.5px] lg:h-[80px] lg:w-[70px] flex-shrink-0 absolute transition duration-500;
  }

  .streamer-mode-left-icon {
    @apply streamer-mode-icon left-2 top-1.5;
  }

  .streamer-mode-left-icon-selected {
    @apply streamer-mode-left-icon -translate-x-2 -translate-y-1.5;
  }

  .streamer-mode-right-icon {
    @apply streamer-mode-icon right-2 top-1.5;
  }

  .streamer-mode-right-icon-selected {
    @apply streamer-mode-right-icon translate-x-2 -translate-y-1.5;
  }

  .streamer-mode-bottom-icon {
    @apply streamer-mode-icon bottom-3 left-1/2 transform -translate-x-1/2;
  }

  .streamer-mode-bottom-icon-selected {
    @apply streamer-mode-bottom-icon translate-y-1.5;
  }

  .streamer-mode-shine-icon {
    @apply streamer-mode-icon top-2 lg:top-4 left-1/2 transform -translate-x-1/2 opacity-0;
  }

  .streamer-mode-shine-icon-selected {
    @apply streamer-mode-shine-icon translate-y-1.5 opacity-100;
  }

  .custom-mode-icon {
    @apply flex-shrink-0 absolute transition duration-500;
  }

  .custom-mode-left-icon {
    @apply custom-mode-icon left-0 top-10;
  }

  .custom-mode-left-icon-selected {
    @apply custom-mode-left-icon -translate-x-4;
  }

  .custom-mode-right-icon {
    @apply custom-mode-icon right-0 top-9;
  }

  .custom-mode-right-icon-selected {
    @apply custom-mode-right-icon translate-x-4;
  }

  .confirm-button-shine {
    @apply flex-shrink-0 absolute transition duration-500 top-1/2 left-1/3 -translate-y-1/2 -translate-x-full;
  }

  .confirm-button-shine-hovered {
    @apply flex-shrink-0 absolute transition duration-500 top-1/2 left-1/3 -translate-y-1/2 translate-x-full;
  }

  .remove-button-shine {
    @apply flex-shrink-0 absolute transition duration-500 top-1/2 left-1/3 -translate-y-1/2 -translate-x-1/3;
  }

  .remove-button-shine-hovered {
    @apply flex-shrink-0 absolute transition duration-500 top-1/2 left-1/3 -translate-y-1/2 translate-x-1/3;
  }
}

/* Map styles */

.hiddenElement {
  max-height: 0;
  overflow: hidden;
  visibility: hidden;
  /*transition: max-height 0.2s ease-in-out;*/
  /* use different properties to completely hide. but do not give width:0 */
}

.displayElement {
  transition: max-height 0.2s ease-in-out;
  /* note that we're transitioning max-height, not height!*/
  max-height: 50rem;
  /* still have to hard-code a value!*/
}

/* Sendbird styles */

.shadow-avatar {
  box-shadow: inset 0 0 12px rgba(0, 0, 0, 0.5);
}

.sendbird-theme--dark .sendbird-conversation {
  background-color: rgb(15, 18, 25) !important;
  border: 0 !important;
  border-radius: 0;
}

.sendbird-theme--dark .sendbird-chat-header {
  background-color: rgb(15, 18, 25) !important;
  border: 0 !important;
  border-radius: 0;
}

.sendbird-message-input .sendbird-message-input--placeholder {
  font-family: Lexend, sans-serif !important;
  font-weight: 400 !important;
}

.sendbird-label {
  font-family: 'DM+Sans', sans-serif !important;
  font-weight: 300 !important;
}

.sendbird-label--h-2 {
  font-weight: 450 !important;
}

.large-size-message-window .sendbird-label--body-1 {
  max-width: 24rem;
}

.small-size-message-window .sendbird-label--body-1 {
  max-width: 14rem;
}

.sendbird-conversation__messages-padding {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.sendbird-theme--dark .sendbird-text-message-item-body.outgoing {
  background-color: #004263 !important;
  color: white !important;
  border-radius: 0.5rem !important;
}

.sendbird-theme--dark .sendbird-text-message-item-body.incoming {
  background-color: #242b39 !important;
  border-radius: 0.5rem !important;
}

.sendbird-thumbnail-message-item-body {
  max-width: 13rem !important;
  min-width: 13rem !important;
  max-height: 9rem !important;
  min-height: 9rem !important;
}

.sendbird-chat-header__right {
  display: none !important;
}

.sendbird-conversation__scroll-bottom-button {
  display: none !important;
}

.sendbird-chat-header--avatar--group-channel {
  pointer-events: none !important;
}

.sendbird-conversation__footer {
  padding: 0 0 1rem 0 !important;
}

.sendbird-conversation__messages-padding {
  overflow-x: hidden !important;
  scrollbar-width: none !important;
}

.sendbird-conversation__scroll-container {
  display: flex;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  overscroll-behavior: none;
}

.sendbird-avatar {
  pointer-events: none !important;
}

.sendbird-message-input-wrapper .sendbird-message-input-wrapper__message-input {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.sendbird-conversation__messages-padding::-webkit-scrollbar {
  width: 0 !important;
  background: transparent !important;
}

.sendbird-message-input--attach {
  display: none !important;
}

.sendbird-modal {
  z-index: 30;
}

.sendbird-chat-header__left__title {
  max-width: 11rem !important;
}

.sendbird-message-input .sendbird-message-input--send {
  right: 12px !important;
  bottom: 12px !important;
}

.sendbird-notification {
  display: none !important;
}

.icon-send_svg__fill {
  fill: #c3ff00 !important;
}

.sendbird-message-input--textarea::-webkit-scrollbar {
  width: 0 !important;
  background: transparent !important;
}

.sendbird-message-input--textarea {
  scrollbar-width: none !important;
}

.swiper-slide {
  width: auto !important;
}

.earth {
  width: 700px;
  height: 685px;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0 solid #6355ff;
  -webkit-text-fill-color: #202023;
  -webkit-box-shadow: 0 0 0 1000px #f0f0f0 inset;
  transition: background-color 5000s ease-in-out 0s;
}

table th:first-child {
  border-radius: 8px 0 0 8px;
}

table th:last-child {
  border-radius: 0 8px 8px 0;
}

.custom-toast {
  padding: 0 !important;
  margin: 0 !important;
  align-items: stretch !important;
}

.sendbird-message-content.incoming
  .sendbird-message-content__middle
  .sendbird-message-content__middle__sender-name {
  overflow-y: hidden !important;
}

.react-select__menu-portal {
  z-index: 50 !important;
}

.react-select-container-v2 {
  padding: 0.375rem !important;
}

.react-select__input {
  box-shadow: none !important;
  color: transparent !important;
  caret-color: transparent !important;
}

.react-select__indicator-separator {
  visibility: hidden !important;
}

.react-select-container {
  padding: 0 !important;
}

.react-select__single-value {
  color: white !important;
}

.react-select__menu {
  background-color: #1a1e29 !important;
}

.will-wrapper::after {
  content: '';
  position: absolute;
  width: 2px;
  height: 60%;
  top: 100px;
  left: 29px;
  border-left: 1px dashed white;
}

.Toastify__toast-container {
  width: 100% !important;
}

@media (min-width: 500px) {
  .Toastify__toast-container {
    width: 400px !important;
  }
}

.Toastify__toast {
  border-radius: 0 !important;
}

.checkout-button {
  position: absolute;
  -webkit-animation: linear infinite;
  -webkit-animation-name: move;
  -webkit-animation-duration: 4s;
  animation: move 4s linear infinite;
}

.gopro-button {
  position: absolute;
  -webkit-animation: linear infinite;
  -webkit-animation-name: move;
  -webkit-animation-duration: 1s;
  animation: move 1s linear infinite;
}

.select-teammate-card {
  position: relative;
  overflow: hidden;
  background: linear-gradient(
    180deg,
    rgba(8, 9, 12, 0) 0%,
    rgba(8, 9, 12, 0.6) 100%
  );
  border: 0 solid transparent; /* 初始透明边框 */
  transition:
    border-color 0.1s ease 0.3s,
    border-width 0.1s ease 0.3s; /* 过渡效果和延迟 */
}

.selected-teammate-card {
  position: relative;
  overflow: hidden;
  background: linear-gradient(
    180deg,
    rgba(8, 9, 12, 0) 0%,
    rgba(8, 9, 12, 0.6) 100%
  );
  border: 4px solid #c3ff00;
  transition:
    border-color 0.1s ease 0.3s,
    border-width 0.1s ease 0.3s; /* 过渡效果和延迟 */
}

.about-div {
  position: absolute;
  bottom: -150%;
  width: 100%;
  height: 150%;
}

.select-teammate-card:hover .about-div {
  animation: bounceUp-select-teammate-card 0.4s forwards;
}

@keyframes bounceUp-select-teammate-card {
  0% {
    bottom: -150%;
    opacity: 0;
  }
  100% {
    bottom: -50%;
    opacity: 1;
  }
}

@keyframes bounceDown-select-teammate-card {
  0% {
    bottom: -50%;
  }
  30% {
    bottom: -40%;
  }
  100% {
    bottom: -150%;
  }
}

.has-hovered .select-teammate-card:not(:hover) .about-div {
  animation: bounceDown-select-teammate-card 0.3s forwards;
}

@keyframes compressAndMove {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1.1);
  }
}

@keyframes restore {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1) translate(0, 0);
  }
}

.image-container {
  transition: transform 0.3s ease;
}

.group:hover .image-container {
  animation: compressAndMove 0.4s forwards;
}

.group:not(:hover) .image-container {
  animation: restore 0.4s forwards;
}

.select-teammate-container {
  display: flex;
  position: relative;
  transition: justify-content 0.5s ease;
}

.card {
  transition: all 0.5s ease; /* 平滑過渡卡片樣式變化 */
  opacity: 1; /* 卡片初始為完全不透明 */
}

.fadedCard {
  opacity: 0; /* 未選中卡片變透明 */
  transform: scale(0.5); /* 並縮小 */
  transition:
    opacity 0.5s,
    transform 0.5s; /* 平滑過渡效果 */
}

@keyframes blinkAnimation {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.blinkAnimation-div {
  animation: blinkAnimation 2s infinite;
}

.centerCard {
  position: absolute;
  left: 50%;
  transform: translateX(-50%); /* 居中卡片 */
  z-index: 10;
}

@keyframes move {
  0% {
    left: 0;
    opacity: 1;
  }
  50% {
    left: 50%;
    opacity: 0.7;
  }
  70% {
    left: 70%;
    opacity: 0.5;
  }
  90% {
    left: 90%;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 0;
  }
}

.sendbird-og-message-item-body__og-thumbnail {
  display: none !important;
}

.sendbird-og-message-item-body {
  max-width: 17rem !important;
  min-width: 4rem !important;
}

.sendbird-og-message-item-body__text-bubble {
  background-color: #004263 !important;
}

.sendbird-og-message-item-body__text-bubble .sendbird-word {
  display: none !important;
}

.sendbird-og-message-item-body__description {
  background-color: rgb(36, 43, 57) !important;
}

.intro-radial-gradient {
  background: radial-gradient(100% 100% at 0% 0%, #628000 0%, #000000 100%);
}

.under-line-text {
  position: relative;
  display: inline-block;
}

.under-line-text::after {
  content: '';
  position: absolute;
  bottom: -6px;
  left: 50%;
  right: 50%;
  height: 1px;
  background-color: #414e69;
  transition: all 0.5s ease;
}

.under-line-text:hover::after,
.under-line-text.under-line-selected::after {
  left: 0;
  right: 0;
}

.selected-game-card {
  border: 2px solid #c3ff00;
}

.select-game-card {
  border: 3px solid transparent;
}

.shine-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(195, 255, 0, 0.2) 100%
  );
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  mix-blend-mode: screen;
  opacity: 0;
}

.shine-overlay-v2 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(157, 205, 0, 0.79) 0%,
    rgba(39, 51, 0, 0.79) 100%
  );
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  opacity: 0;
}

.shine-overlay-active,
.group:hover .shine-overlay,
.group:hover .shine-overlay-v2 {
  opacity: 1;
}

.card-style-enabled {
  cursor: pointer;
  opacity: 95%;
}

.card-style-disabled {
  opacity: 40%;
}

.icon-game-transition {
  transition:
    transform 0.5s,
    opacity 0.5s;
  transform: translateY(10px);
  opacity: 0;
}

.icon-btn-animation,
.icon-avatars-animation {
  transform: translateY(30px);
  opacity: 0;
}

@keyframes moveUpAndBack {
  0% {
    transform: translateY(100px);
    opacity: 1;
  }
  80% {
    opacity: 1;
    transform: translateY(-4px);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes moveUpAndBackAvatars {
  0% {
    transform: translateY(30px);
    opacity: 1;
  }
  80% {
    opacity: 1;
    transform: translateY(-4px);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.group:hover .icon-btn-animation {
  animation: moveUpAndBack 0.4s ease forwards;
}
.group:hover .icon-avatars-animation {
  animation: moveUpAndBackAvatars 0.4s ease forwards;
}

.icon-game-transition-active,
.group:hover .icon-game-transition {
  transform: translateY(0);
  opacity: 1;
}

.image-transform {
  transition: transform 0.5s ease-out;
  transform-origin: top center; /* 以图片的顶部中心为变换原点 */
}

.group:hover .image-container {
  animation: compressAndMove 0.4s forwards;
}

.group:not(:hover) .image-container {
  animation: restore 0.4s forwards;
}

.image-transform-v2 {
  transition: transform 0.1s ease-out;
  transform-origin: center;
}

.image-transform-active,
.group:hover .image-transform {
  transform: scale(1.18);
}

.group:hover .image-transform-v2 {
  animation: bounceUp 0.4s forwards;
}

@keyframes bounceUp {
  0% {
    transform: scale(1);
  }
  80% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1.08);
  }
}

.group:not(:hover) .image-transform-v2 {
  animation: bounceDown 0.4s forwards;
}

@keyframes bounceDown {
  0% {
    transform: scale(1.08);
  }
  100% {
    transform: scale(1);
  }
}

.image-transform-bottom {
  opacity: 0;
  transition: transform 0.1s ease-out;
}
.group:hover .image-transform-bottom {
  animation: bounceUpAndshow 0.4s forwards;
}

@keyframes bounceUpAndshow {
  0% {
    transform: translateY(30%);
    opacity: 1;
  }
  80% {
    transform: translateY(-10%);
    opacity: 1;
  }
  100% {
    transform: translateY(9%);
    opacity: 1;
  }
}

.gradient-textStyle {
  text-align: center;
  background: linear-gradient(180deg, #6355ff 0%, #00eaea 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@keyframes moveAndBack {
  0% {
    transform: translateX(-50%);
  }
  80% {
    transform: translateY(24px) translateX(-50%);
  }
  100% {
    transform: translateY(22px) translateX(-50%);
  }
}

.moving-element {
  transform: translateX(-50%);
}

.group:hover .moving-element {
  animation: moveAndBack 0.4s ease forwards;
}

/*.svg-container .svg-circle,*/
/*.svg-container .svg-path {*/
/*  transition: fill 0.4s;*/
/*}*/

/*.svg-container:hover .svg-circle {*/
/*  fill: #5e7198;*/
/*  fill-opacity: 0.32;*/
/*  stroke: white;*/
/*}*/

/*.svg-container:hover .svg-path {*/
/*  fill: white;*/
/*}*/

.arrow-button {
  background-color: #242b39;
  color: #fff;
  border: none;
  padding: 10px 20px;
  height: 60px;
  width: 100%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.arrow-button:hover {
  background-color: #414e69; /* Darker background on hover */
}

/* Unique styles for left and right arrow buttons */
.left-arrow-button,
.right-arrow-button {
  clip-path: polygon(94% 0, 100% 50%, 94% 100%, 0 100%, 6% 50%, 0 0);
}

.left-arrow-button {
  padding: 0 20px; /* Specific padding for left button */
  clip-path: polygon(100% 0%, 94% 50%, 100% 100%, 6% 100%, 0% 50%, 6% 0);
}

/*.hexagonal-button {*/
/*  position: relative;*/
/*  width: 59px; !* Width of the button *!*/
/*  height: 69px; !* Height of the button *!*/
/*  clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);*/
/*  background-color: #2e374a; !* Dark primary color for the button *!*/
/*  display: flex;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  border: none; !* No outer border *!*/
/*  padding: 0; !* No padding *!*/
/*  cursor: pointer;*/
/*  overflow: hidden; !* Ensures no inner content overflows the clip-path shape *!*/
/*}*/

.border-span {
  width: 53px; /* Slightly smaller to create a border effect */
  height: 63px; /* Slightly smaller to create a border effect */
  clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
  background-color: #0f1219; /* Lighter color for the "border" effect */
  position: absolute; /* Positioned relative to the button */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Precise centering */
}

.hexagonal-button-2 {
  clip-path: polygon(10% 0, 90% 0, 100% 50%, 90% 100%, 10% 100%, 0% 50%);
}

@keyframes blinkColor {
  0%,
  100% {
    background-color: #0f1219;
  }
  50% {
    background-color: #1f2531;
  }
}

.blinking {
  animation: blinkColor 0.4s ease 2;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

html,
body {
  overflow-x: hidden;
}

/* Branding on the banner */
a#CybotCookiebotDialogPoweredbyCybot,
div#CybotCookiebotDialogPoweredByText {
  display: none;
}

/* Branding on the Privacy trigger */
#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-main-logo {
  display: none;
}

table th:first-child {
    border-radius: 0 !important;
}

.animate-scroll-down,
.animate-scroll-up {
  will-change: transform;
  transform: translateY(0);
}

.animate-scroll-down:hover,
.animate-scroll-up:hover {
  animation-play-state: paused;
}
